import logo from "./logo.svg";

import { Box, Container, Link, Typography } from "@mui/material";
import Chat from "./Chat";
import Footer from "./Footer";

function HomePage() {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          mt: 10,
          mb: 2,
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          gap: "20px",
          alignItems: "end",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            height: { xs: "60px", sm: "90px" },
            width: { xs: "60px", sm: "90px" },
          }}
          component="img"
          src={logo}
          alt="logo"
        />
        <Typography
          component="h1"
          variant="h3"
          sx={{ display: { xs: "flex", sm: "none" } }}
        >
          AnonChatGPT
        </Typography>
        <Typography
          component="h1"
          variant="h2"
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          AnonChatGPT
        </Typography>
      </Box>
      <Typography variant="body1" textAlign="center">
        Incognito ChatGPT. No account required.
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        sx={{ color: "gray", mt: 2 }}
      >
        Sponsored:{" "}
        <Link href="https://christina.app" target="_blank">
          Anxiety on the rise? Calm your mind with Christina.app 🧠
        </Link>
      </Typography>
      <Box sx={{ mt: 16 }}>
        <Chat />
      </Box>
      <Box sx={{ mt: 10 }}>
        <Footer />
      </Box>
    </Container>
  );
}

export default HomePage;
